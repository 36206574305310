/*
 * @Author: mikey.zhaopeng 
 * @Date: 2020-07-31 10:21:28 
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: yyyy-08-Tu 05:29:47
 */

import * as API from '@/api/index'

 export default{
     //消耗列表
     GetTreatList:params=>{
         return API.POST('api/refundTreatBill/treatList',params)
     },
    //退消耗申请
    RefundTreatBillApply:params =>{
    return API.POST('api/refundTreatBill/create',params)
    },
      /**  52.11.获取项目消耗经手人 */
  treatGoodsProjectHandler: params => {
    return API.POST('api/treatHandler/projectHandler', params)
  },
  /**  52.12.获取产品消耗经手人 */
  treatGoodsProductHandler: params => {
    return API.POST('api/treatHandler/productHandler', params)
  },
  /**  52.13.获取储值卡消耗经手人 */
  treatGoodsSavingCardHandler: params => {
    return API.POST('api/treatHandler/savingCardHandler', params)
  },
 }